import React from 'react';
import './Explainer.css';
import { GameButton } from '@magicyard/gptrivia-shared/components/GameButton/GameButton';
import { useGameObject } from '../store/GameContext';

export const Explainer = () => {
  const { moves } = useGameObject();
  return (
    <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <GameButton variant={0} onClick={() => moves.finishedTalking()} size={'sm'}>
        Skip intro
      </GameButton>
    </div>
  );
};
