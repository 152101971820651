import React, { useEffect, useMemo } from 'react';
import { useGameObject } from '../store/GameContext';
import { Phases } from '@magicyard/gptrivia-game/src/Game';
import { ActiveDrawingSimultaneous } from './Stages/active-drawing-simultaneous/ActiveDrawingSimultaneous';
import Background from '../components/Background/Background';
import { NavigationBar } from '../components/navigation-bar/NavigationBar';
import { LoaderTemplate } from '../components/loader-template/LoaderTemplate';
import { PickCategory } from './Stages/PickCategory';
import { track } from '@magicyard/shared/src/analytics';
import { WinnerScene } from './WinnerScene';
import { Explainer } from './Explainer';

const LookAtScreen = () => {
  return <LoaderTemplate />;
};

const ScoringPhase = () => {
  useEffect(() => {
    track('Scoring Phases Started');
  }, []);
  return <LookAtScreen />;
};

const CreatingQuiz = () => {
  const { G } = useGameObject();
  useEffect(() => {
    track('Generating Quiz Shown');
  }, []);
  return (
    <div style={{ width: '80%', textAlign: 'center', marginLeft: '10%' }}>
      Creating a customized trivia about {G.category}...
    </div>
  );
};

const StageManager = (props: { onStartNewGame: () => void }) => {
  const { ctx, G, playerID } = useGameObject();

  const stages: Record<Phases, () => JSX.Element> = useMemo(
    () => ({
      [Phases.Sync]: LookAtScreen,
      [Phases.Explainer]: Explainer,
      [Phases.ViewingHighScore]: PickCategory,
      [Phases.PickCategory]: PickCategory,
      [Phases.Prompting]: CreatingQuiz,
      [Phases.Playing]: ActiveDrawingSimultaneous,
      [Phases.Scoring]: ScoringPhase,
      [Phases.GameEnd]: () => <WinnerScene onStartNewGame={props.onStartNewGame} />,
    }),
    []
  );
  const phase = ctx.phase as Phases;
  const Stage = stages[phase];
  return (
    <Background>
      <NavigationBar header={''} />
      <Stage />
    </Background>
  );
};

export default StageManager;
