import React, { ReactChild, useEffect, useState } from 'react';
import { Communication, YardController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { SubmittableInput } from './components/submittable-input/SubmittableInput';
import { Profile } from '@magicyard/shared/platform/lib/api';
import './YardScreen.css';
import { assertNever } from '@magicyard/gptrivia-game/src/utils/typeUtils';
import { DEFAULT_NAME } from './App';
import { NameDialog } from './components/YardAndDisplayScreen';
import { track } from '@magicyard/shared/src/analytics';
import { GameButton } from '@magicyard/gptrivia-shared/components/GameButton/GameButton';

type Steps = 'landing' | 'choice' | 'pc' | 'tv' | 'input';

export const useEditName = (profile: Profile, onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void) => {
  const [isEditingName, setIsEditingName] = useState<string | null>(profile.name === DEFAULT_NAME ? '' : null);

  if (isEditingName !== null) {
    return (
      <NameDialog
        isOpen={true}
        onClose={() => setIsEditingName(null)}
        onProfileUpdate={onProfileUpdate}
        name={profile.name}
      />
    );
  }
  return null;
};

const YARD_STEP_KEY = 'yard_step';

const getKey = (controller: YardController) => {
  return YARD_STEP_KEY + controller.yard.id;
};

export const YardScreen = ({
  comms,
  controller,
  onCodeEntered,
  onProfileUpdate,
}: {
  comms: Communication;
  onCodeEntered: (code: string) => void;
  controller: YardController;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
}) => {
  const lastVal = localStorage.getItem(getKey(controller)) as Steps;
  const [step, setStep] = useState<Steps>(lastVal ?? 'landing');
  const x = useEditName(controller.profile, onProfileUpdate);
  if (x !== null && step === 'choice') {
    return x;
  }

  return (
    <div className={'yard_screen-root'}>
      {/*{step !== 'landing' && (*/}
      {/*  <VoiceChatActivatedOverlay*/}
      {/*    comms={comms}*/}
      {/*    voiceChatState={controller.yard.voiceChatState}*/}
      {/*    subtitle={'Turn on voice chat to speak with your friends'}*/}
      {/*  />*/}
      {/*)}*/}
      <GetComponentForStep
        step={step}
        setStep={(s) => {
          localStorage.setItem(getKey(controller), s);
          setStep(s);
        }}
        onCodeEntered={onCodeEntered}
        controller={controller}
        comms={comms}
      />
    </div>
  );
};

const GetComponentForStep = ({
  step,
  setStep,
  onCodeEntered,
  controller,
  comms,
}: {
  step: Steps;
  setStep: (step: Steps) => void;
  onCodeEntered: (code: string) => void;
  controller: YardController;
  comms: Communication;
}) => {
  useEffect(() => {
    track('Invited Landing page loaded');
  }, []);
  switch (step) {
    case 'landing':
      return (
        <>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
            <Title>Welcome to Doodle dash!</Title>
            <YardPart controller={controller} />
          </div>
          <div style={{ marginBottom: '15%' }}>
            <GameButton onClick={() => setStep('choice')} variant={0} disable={false} size={'sm'}>
              Join them!
            </GameButton>
          </div>
        </>
      );
    case 'choice':
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Title>You need a display to play Doodle Dash</Title>
          <div className={'yard-tv_pc_container'} style={{ margin: '6vh 0' }}>
            <div
              className={'yard_screen-tv'}
              onClick={() => {
                track('Invited connect TV clicked');
                setStep('tv');
              }}
            />
            <div
              className={'yard_screen-pc'}
              onClick={() => {
                track('Invited connect PC clicked');
                setStep('pc');
              }}
            />
          </div>
        </div>
      );
    case 'pc':
      return (
        <div className={'yard-pc_container'}>
          <Title>
            Go to <span style={{ color: '#af4bd0' }}>play.doodledash.tv</span> in your browser
          </Title>
          <div className={'yard_screen-browser'} />
          <GameButton onClick={() => setStep('input')} variant={0} disable={false} size={'sm'}>
            Done!
          </GameButton>
          <GameButton onClick={() => setStep('choice')} variant={1} size={'sm'}>
            Back..
          </GameButton>
        </div>
      );
    case 'tv':
      return (
        <div className={'yard-pc_container'}>
          <Title>
            Download the <span style={{ color: '#af4bd0' }}>Doodle Dash</span> app on your TV
          </Title>
          <div className={'yard-tv_download_icon'}></div>
          <GameButton onClick={() => setStep('input')} variant={0} disable={false}>
            Done!
          </GameButton>
          <GameButton onClick={() => setStep('choice')} variant={1}>
            Back..
          </GameButton>
        </div>
      );
    case 'input':
      return (
        <>
          <Title>Enter the room code</Title>
          <div className={'yard_screen-pin_example'} />
          <div
            style={{
              marginTop: '5vh',
              alignItems: 'center',
            }}
          >
            <SubmittableInput
              submitData={{
                submitText: 'Submit',
                onSubmit: (code) => {
                  onCodeEntered(code);
                  track('Invited Room code submitted');
                },
              }}
              autoFocus={false}
              tabIndex={-1}
              defaultValue={''}
              placeholder={'Enter code..'}
              type={'tel'}
              pattern="[0-9]*"
              inputMode={'numeric'}
              forceEnglish={false}
            />
          </div>
          <GameButton onClick={() => setStep('choice')} variant={1}>
            Back..
          </GameButton>
        </>
      );
    default:
      assertNever(step);
  }
};

const YardPart = ({ controller }: { controller: YardController }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: 12, fontSize: 24 }}>{controller.yard.name}</div>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
        {controller.yard.controllers
          .filter((x) => x.profile.id !== controller.profile.id)
          .map((c) => (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={c.profile.id}>
              <div
                style={{
                  backgroundImage: `url(${c.profile.avatarUrl})`,
                  width: '10vh',
                  height: '10vh',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  backgroundSize: 'contain',
                }}
              />
              {c.profile.name}
            </div>
          ))}
      </div>
    </>
  );
};

const Title = ({ children }: { children: ReactChild | ReactChild[] }) => (
  <div className={'yard_screen-title'}>{children}</div>
);
