import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import { SubmittableInput, SubmittableInputSubmitBtn } from '../../components/submittable-input/SubmittableInput';
import './PickCategory.css';
import { Textfit } from 'react-textfit';
import { track } from '@magicyard/shared/src/analytics';
import { UserIcon } from '@magicyard/gptrivia-shared/components/UserIcon';
import { DAILY_CHALLENGE_ID, getDidGroupAlreadyPlay } from '@magicyard/gptrivia-game/src/utils/moves.util';
import { sanitizeForCompare } from '@magicyard/gptrivia-game/src/promptTypes';
import { SimpleDailyQuiz } from '@magicyard/gptrivia-game/src/Types';
import { FixedSizeList } from 'react-window';
import { getAllDailyQuizzes } from '@magicyard/gptrivia-game/src/dailyQuizApi';

const isDevMode = (devMode: number) => {
  return devMode >= 5;
};

export const PickCategory = () => {
  const { moves, G } = useGameObject();
  const [input, setInput] = useState('');
  const [err, setErr] = useState<string>('');
  const [devMode, setDevMode] = useState(0);
  const [quizzes, setQuizzes] = useState<SimpleDailyQuiz[]>([]);
  const [listItemHeight, setListItemHeight] = useState((window.innerHeight / 100) * 10.5);

  useLayoutEffect(() => {
    const handleResize = () => {
      setListItemHeight((window.innerHeight / 100) * 10.5);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getAllDailyQuizzes(
      G.players.map((p) => ({
        id: p.controller_id,
        name: p.name,
        avatarUrl: p.avatarUrl,
      }))
    ).then(setQuizzes);
  }, []);
  const handleSubmit = (value: string, isSuggestion: boolean) => {
    if (value.length < 2) {
      setDevMode((old) => old + 1);
      setErr('Minimum 2 characters');
      return;
    }
    const existing = quizzes.find((x) => sanitizeForCompare(x.category) === sanitizeForCompare(value));
    if (existing !== undefined && getDidGroupAlreadyPlay(existing) && !isDevMode(devMode)) {
      setErr('Someone in the group had already done this quiz');
      moves.chooseCategory(value, { quizId: existing.id, highScore: existing.highScoreSessions }, true);
      return;
    } else {
      track('Category Chosen', { category: value, isSuggestion: isSuggestion });
      moves.chooseCategory(
        value,
        existing === undefined ? null : { quizId: existing.id, highScore: existing.highScoreSessions }
      );
    }
  };

  if (quizzes.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        Loading...
      </div>
    );
  }

  const renderList = quizzes.filter(
    (x) =>
      sanitizeForCompare(x.category)?.includes(sanitizeForCompare(input) ?? '') ||
      x.tags?.some((tag) => sanitizeForCompare(tag)?.includes(sanitizeForCompare(input) ?? ''))
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {isDevMode(devMode) && (
        <div style={{ position: 'absolute', top: '0', zIndex: 1000, background: 'red' }}>dev mode</div>
      )}
      <div className={'pick-category-play-specifc-topic'} style={{ color: '#6DFFFD' }}>
        Play a specfic topic
      </div>
      <div style={{ position: 'relative' }}>
        <SubmittableInput
          hasIcon={true}
          submitData={null}
          value={input}
          onChange={(v) => {
            setInput(v);
            setErr('');
            setDevMode(0);
          }}
          placeholder={'Search'}
          forceEnglish={false}
        />
        <div className={'pick_category-search'} />
      </div>
      <div className={'pick_category-err'}>{err}</div>
      <div className={'pick_category-suggestion_body'}>
        <div className={'pick_category-btn-container'} style={{ width: '30vh' }}>
          <SubmittableInputSubmitBtn
            onSubmit={() => handleSubmit(input, false)}
            text={'Create now!'}
            disabled={input.length === 0}
          />
        </div>
        <div className={'pick_category-suggestion_title'}>{renderList.length === 0 ? '' : 'Created by others'}</div>
        <div
          className={`pick_category-suggestion_container ${
            renderList.length === 0 ? 'pick_category-suggestion_container_empty' : ''
          }`}
        >
          <FixedSizeList itemSize={listItemHeight} height={500} itemCount={renderList.length} width={'100%'}>
            {({ style, index }) => {
              const d = renderList[index];
              const highSession = d.highScoreSessions;
              return (
                <div style={style} className={'pick_category-suggestion-container'}>
                  <div
                    className={'pick_category-suggestion'}
                    key={d.category}
                    onClick={() => handleSubmit(d.category, true)}
                  >
                    {highSession !== null && (
                      <div className={'pick_category-highscore_container'}>
                        <div className={'pick_category-highscore_text_container'}>
                          <Textfit mode={'single'} className={'pick_category-highscore_text'}>
                            {highSession.score}
                          </Textfit>
                        </div>
                        <div className={'pick_category-highscore_avatar'}>
                          <UserIcon avatarUrl={highSession.playerProfiles[0].avatarUrl} />
                        </div>
                      </div>
                    )}
                    {getDidGroupAlreadyPlay(d) && <div className={'pick_category-checkmark'} />}
                    <Textfit
                      forceSingleModeWidth={false}
                      mode={'single'}
                      style={{
                        width: '80%',
                        height: '60%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {capitalizeFirstLetter(d.category)}
                    </Textfit>
                  </div>
                </div>
              );
            }}
          </FixedSizeList>
        </div>
      </div>
    </div>
  );
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
