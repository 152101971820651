import React, { HTMLInputTypeAttribute, useEffect } from 'react';

import './SubmittableInput.css';
import { Simulate } from 'react-dom/test-utils';
import submit = Simulate.submit;

interface SubmittableInputProps {
  value?: string;
  defaultValue?: string;
  isValid?: boolean;
  onChange?: (value: string) => void;
  submitData: { onSubmit: (value: string) => void; submitText: string } | null;
  style?: React.CSSProperties;
  forceEnglish: boolean;
  type?: HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  pattern?: string;
  hasIcon?: boolean;
}

export const SubmittableInput = ({
  value,
  defaultValue,
  style,
  submitData,
  onChange,
  isValid,
  forceEnglish,
  type = 'text',
  inputMode,
  placeholder,
  tabIndex,
  autoFocus,
  pattern,
  hasIcon,
}: SubmittableInputProps) => {
  const [msg, setMsg] = React.useState('');
  const [msgTimeout, setMsgTimeout] = React.useState<number | undefined>(undefined);
  const [text, setText] = React.useState(value ?? defaultValue ?? '');

  useEffect(() => {
    return () => {
      window.clearTimeout(msgTimeout);
    };
  }, [msgTimeout]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = forceEnglish ? event.target.value.replace(/[^a-zA-Z ]/g, '') : event.target.value;
    if (event.target.value.length !== newVal.length) {
      setMsg('Only english letters are allowed');
      window.clearTimeout(msgTimeout);
      setMsgTimeout(
        window.setTimeout(() => {
          setMsg('');
        }, 1500)
      );
    } else {
      setMsg('');
      if (onChange !== undefined) {
        onChange(newVal);
      }
      setText(newVal);
    }
  };
  const handleClear = () => {
    setMsg('');
    if (onChange !== undefined) {
      onChange('');
    }
    setText('');
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitData?.onSubmit(text);
    }
  };

  return (
    <div className={'submittable-input_root'}>
      <input
        autoFocus={autoFocus}
        value={text}
        onChange={handleChange}
        className={`submittable-input_input ${hasIcon !== undefined ? 'submittable-input_input_with_icon' : ''}`}
        type={type}
        inputMode={inputMode}
        onKeyUp={handleKeyPress}
        placeholder={placeholder}
        pattern={pattern}
      />
      {text.length !== 0 && <div className={'submittable-input_x'} onClick={handleClear} />}
      {submitData !== null && (
        <div className={'submittable-input_input_container'}>
          <SubmittableInputSubmitBtn onSubmit={submitData.onSubmit} text={submitData.submitText} />
        </div>
      )}
    </div>
  );
};

export const SubmittableInputSubmitBtn = ({
  onSubmit,
  text,
  disabled,
}: {
  onSubmit: (val: string) => void;
  text: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`submittable-input_submit ${disabled ? 'submittable-input_submit_disabled' : ''}`}
      onClick={disabled ? undefined : () => onSubmit(text)}
    >
      {text}
    </div>
  );
};
