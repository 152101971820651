import React from 'react';
import { useGameObject } from '../../../store/GameContext';
import './ActiveDrawingSimultaneous.css';
import { canUsePowerUp, getOptionsWithDisabled } from '@magicyard/gptrivia-game/src/utils/moves.util';
import { GameButtonWithUser } from '@magicyard/gptrivia-shared/components/GameButton/GameButtonWithUser';
import { LoaderTemplate } from '../../../components/loader-template/LoaderTemplate';
import { track } from '@magicyard/shared/src/analytics';

export const DRAWING_STORAGE_KEY = 'drawingData';
export const getStorageKeyForPlayer = (playerID: string) => {
  return DRAWING_STORAGE_KEY + playerID;
};

export const ActiveDrawingSimultaneous = () => {
  const { G, playerID, moves } = useGameObject();
  if (G.endedRevealingLastSentenceAt === null) {
    return <LoaderTemplate />;
  }

  const optionsWithDisabled = getOptionsWithDisabled(G);
  const mostSelectedQuestion = optionsWithDisabled.reduce((acc, x) => {
    if (x.selectedBy !== undefined) {
      return Math.max(x.selectedBy.length, acc);
    }
    return acc;
  }, 0);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90%',
      }}
    >
      <div className={'active-drawing-simultaneous_title'}>
        A majority ({Math.ceil(G.players.length / 2) - mostSelectedQuestion}
        {mostSelectedQuestion === 0 ? '' : ' more'}) must select the same answer.
      </div>
      {getOptionsWithDisabled(G).map((oi) => (
        <div style={{ marginBottom: 20 }} key={oi.i}>
          <GameButtonWithUser
            maxFontSize={20}
            i={oi.i}
            onClick={() => {
              track('Answer Chosen', { answer: oi.option });
              moves.selectAnswer(oi.option);
            }}
            size={'sm'}
            avatarUrl={oi.selectedBy === undefined ? undefined : oi.selectedBy.map((s) => G.players[+s].avatarUrl)}
            text={oi.option}
            disable={oi.disabled}
          />
        </div>
      ))}
      <div className={'active-drawing-simultaneous_powerups'}>
        <div
          className={`active-drawing-simultaneous_double ${
            G.powerUpsUsedForPromptIndex[G.promptIndex]?.type === 'double'
              ? ' active-drawing-simultaneous_powerup_active'
              : canUsePowerUp(G, 'double')
              ? ''
              : 'active-drawing-simultaneous_powerup_disabled'
          }`}
          onClick={() => moves.usePowerUp('double')}
        />
        <div
          className={`active-drawing-simultaneous_5050 ${
            G.powerUpsUsedForPromptIndex[G.promptIndex]?.type === 'fiftyFifty'
              ? ' active-drawing-simultaneous_powerup_active'
              : canUsePowerUp(G, 'fiftyFifty')
              ? ''
              : 'active-drawing-simultaneous_powerup_disabled'
          }`}
          onClick={() => moves.usePowerUp('fiftyFifty')}
        />
      </div>
    </div>
  );
};
