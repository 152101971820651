import React, { useEffect, useState } from 'react';
import './WinnerScene.css';
import { GameButton } from '@magicyard/gptrivia-shared/components/GameButton/GameButton';
import { track } from '@magicyard/shared/src/analytics';
import { ratePlayedQuiz } from '@magicyard/gptrivia-game/src/dailyQuizApi';
import { useGameObject } from '../store/GameContext';
import { LoaderTemplate } from '../components/loader-template/LoaderTemplate';

export const WinnerScene = ({ onStartNewGame }: { onStartNewGame: () => void }) => {
  const { playerID, G } = useGameObject();
  const [didRate, setDidRate] = useState(false);
  useEffect(() => {
    track('Winner Scene Shown');
  }, []);

  const rateQuiz = (playedQuizId: number, rating: number) => {
    setDidRate(true);
    ratePlayedQuiz(playedQuizId, rating, G.players[+playerID].controller_id);
  };

  const id = G.playedQuizId;

  if (id === null) {
    return <LoaderTemplate title={'Loading...'} />;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {didRate ? (
        <div style={{ fontSize: '5vh' }}>Thanks for rating!</div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '4vh',
          }}
        >
          <div style={{ marginBottom: '4vh' }}>How was this quiz?</div>
          <GameButton variant={3} onClick={() => rateQuiz(id, 10)} disable={false} size={'sm'}>
            <div className={'winner_scene-thumb winner_scene-thumbs-up'} />
          </GameButton>
          <div style={{ marginBottom: '2vh' }} />
          <GameButton variant={0} onClick={() => rateQuiz(id, 0)} disable={false} size={'sm'}>
            <div className={'winner_scene-thumb winner_scene-thumbs-down'} />
          </GameButton>
        </div>
      )}
      <div style={{ marginTop: '20vh' }}>
        <GameButton variant={3} onClick={onStartNewGame} disable={false} size={'sm'}>
          Play again!
        </GameButton>
      </div>
    </div>
  );
};
